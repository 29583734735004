import React from 'react';
import {Link} from "gatsby";
import {connect} from "react-redux";
import qs from "query-string";
import tw, {GlobalStyles, styled} from 'twin.macro';
import axios from "axios";

import {iState} from "@/state";
import {iState as iStateForm} from "@/state/reducers/form";
import {iState as iStateServer, iStatus, request, reset} from "@/state/reducers/server";
import Loading from "@/components/loading";
import Logo from "@/components/illustrations/logo";
import Helmet from "react-helmet";
import Seo from "@/components/seo";
import Theme from "@/components/theme";
import {IPageProps} from "@/props";
import {apiUrl} from "@/api";

const Wrapper = styled.div(
	() => [
		tw`
		flex
		absolute
		inset-0
		lg:items-center
		lg:justify-center
		lg:h-screen
		px-5
		pt-5
		lg:pt-0
		bg-gradient-to-b from-primary-800 to-primary-background`
	]
);

const SuccessMsg = styled.p(
	() => [
		tw`text-secondary_accent-background text-sm text-center mt-15`
	]
);
const ErrorMsg = styled.p(
	() => [
		tw`text-pink-600 text-sm text-center mt-15`
	]
);

const SendEmailBtn = styled.button(
	() => [
		tw`
		block
		mt-15
		w-full
		py-3
		px-20
		border
		border-transparent
		text-xl
		font-bold
		rounded-md
		text-secondary_accent-text
		bg-secondary_accent-background
		focus:outline-none
		focus:border-primary-500
		focus:shadow-outline-blue
		active:bg-secondary_accent-background
		transition
		duration-150
		ease-in-out
		shadow-md`
	]
);

interface Props extends IPageProps {
	form: iStateForm;
	server: iStateServer;

	reset: typeof reset;
	request: typeof request;
}

const Success: React.FunctionComponent<Props> = (props: Props) => {
	React.useEffect(() => {
		props.reset();
		const params = qs.parse(location.search);
		if (params.s) {
			props.request(`${params.s}`);
		}
	}, []);

	const [emailRequested, setEmailRequested] = React.useState(false);
	const [emailRequestedError, setEmailRequestedError] = React.useState(false);

	let waitMsg = "Waiting for payment to complete";
	const s = props.server.server;

	const paid = s?.isPaid;
	const init = s?.serverStatus == iStatus.NEW;
	const creating = s?.serverStatus == iStatus.CREATING;
	const created = s?.serverStatus == iStatus.CREATED;
	const finished = s?.serverStatus == iStatus.FINISHED;
	const error = s?.serverStatus == iStatus.ERROR;

	if (!paid) {
		waitMsg = "Waiting for payment to complete";
	}

	if (init && paid) {
		waitMsg = "Waiting for worker drone to init";
	}

	if (creating) {
		waitMsg = "Spawning the virtual private server droplet";
	}

	if (created) {
		waitMsg = "Created the virtual private server droplet";
	}

	if (created && !finished) {
		waitMsg = "Installing & configuring the VPN service";
	}

	if (finished) {
		waitMsg = "Service installed, finishing up";
	}

	if (error) {
		waitMsg = "Error creating the VPN service";
	}

	const successMsg = (msg: string) => (
		<div className="flex items-center truncate space-x-3">
			<div className="w-2.5 h-2.5 flex-shrink-0 rounded-full bg-secondary_accent-background"/>
			<p className="font-medium truncate text-normal leading-6">{msg} <span
				className="truncate font-normal text-primary-200">no errors reported</span></p>
		</div>
	);

	const successIfNotErr = (msg: string) => {
		return error ? failMsg() : successMsg(msg);
	};

	const failMsg = () => (
		<div className="flex items-center truncate space-x-3">
			<div className="w-2.5 h-2.5 flex-shrink-0 rounded-full bg-red-400"/>
			<p className="font-medium truncate text-normal leading-6">Error installing your VPN service <span
				className="truncate font-normal text-primary-text">we will retry automatically</span></p>
		</div>
	);

	const reSendEmail = () => {
		axios.get(apiUrl("servers/resendConfig?id=" + s?.id)).then((res) => {
			setEmailRequested(res.data.success);
		}).catch((e) => {
			console.error(e);
			setEmailRequestedError(true);
		});
	};

	const now = new Date().getTime();
	const showResendBtn = (s?.updatedAt || now) < now - 300000 && s?.serverStatus == iStatus.FINISHED && (s?.emailSentCounter || 0) < 2;

	return (<Theme>
			<GlobalStyles/>
			<Helmet bodyAttributes={{class: 'font-body'}}/>
			<Seo {...props}/>

			<Wrapper>
				{props.server.loading && !props.server.server && (
					<Loading size={12} title={"Loading server..."} color={"var(--primary-text)"}/>
				)}

				{props.server.server?.id && (
					<div className={"max-w-screen-md m-auto w-full"}>
						<Link to={"/"} className="block mb-10">
							<Logo width={190} className={"m-auto"}/>
						</Link>
						<div>
							<h3 className="text-lg leading-6 font-medium text-primary-text">
								<b>{props.server?.server?.name}</b>
							</h3>
							<p className="max-w-2xl text-normal leading-5 text-primary-text">
								Monitor your VPN server status
							</p>
						</div>
						<div className="mt-5 border-t border-primary-700 pt-5">
							<dl className={"w-full"}>
								<div className="sm:grid sm:grid-cols-3 sm:gap-4 w-full">
									<dt className="text-normal leading-5 font-medium text-primary-text">
										<b>Payment complete</b>
									</dt>
									<dd className="mt-1 text-normal leading-5 text-primary-text sm:mt-0 sm:col-span-2 text-left">
										{!paid && (
											<Loading size={4} title={waitMsg} color={"var(--primary-text)"}/>
										)}

										{paid && successIfNotErr("payment status is completed")}
									</dd>
								</div>
								<div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-primary-700 sm:pt-5">
									<dt className="text-normal leading-5 font-medium text-primary-text">
										<b>Worker job init</b>
									</dt>
									<dd className="mt-1 text-normal leading-5 text-primary-text sm:mt-0 sm:col-span-2 text-left">
										{init && (
											<Loading size={4} title={waitMsg} color={"var(--primary-text)"}/>
										)}

										{!init && paid && successIfNotErr("install worker initialized")}
									</dd>
								</div>
								<div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-primary-700 sm:pt-5">
									<dt className="text-normal leading-5 font-medium text-primary-text">
										<b>Creating droplet</b>
									</dt>
									<dd className="mt-1 text-normal leading-5 text-primary-text sm:mt-0 sm:col-span-2">
										{creating && (
											<Loading size={4} title={waitMsg} color={"var(--primary-text)"}/>
										)}

										{!creating && !init && successIfNotErr("droplet created")}
									</dd>
								</div>
								<div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-primary-700 sm:pt-5">
									<dt className="text-normal leading-5 font-medium text-primary-text">
										<b>Loading image</b>
									</dt>
									<dd className="mt-1 text-normal leading-5 text-primary-text sm:mt-0 sm:col-span-2">
										{created && (
											<Loading size={4} title={waitMsg} color={"var(--primary-text)"}/>
										)}

										{!created && !creating && !init && successIfNotErr(
											"image loaded")}
									</dd>
								</div>
								<div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-primary-700 sm:pt-5">
									<dt className="text-normal leading-5 font-medium text-primary-text">
										<b>Service configuration</b>
									</dt>
									<dd className="mt-1 text-normal leading-5 text-primary-text sm:mt-0 sm:col-span-2">
										{created && !finished && (
											<Loading size={4} title={waitMsg} color={"var(--primary-text)"}/>
										)}

										{finished && successIfNotErr("service installed and configured")}
									</dd>
								</div>
								<div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-primary-700 sm:pt-5">
									<dt className="text-normal leading-5 font-medium text-primary-text">
										<b>Generate client credentials</b>
									</dt>
									<dd className="mt-1 text-normal leading-5 text-primary-text sm:mt-0 sm:col-span-2">
										{created && !finished && (
											<Loading size={4} title={waitMsg} color={"var(--primary-text)"}/>
										)}

										{finished && successIfNotErr("generated config files")}
									</dd>
								</div>
								<div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-primary-700 sm:pt-5">
									<dt className="text-normal leading-5 font-medium text-primary-text">
										<b>Sending user email</b>
									</dt>
									<dd className="mt-1 text-normal leading-5 text-primary-text sm:mt-0 sm:col-span-2">
										{created && !finished && (
											<Loading size={4} title={waitMsg} color={"var(--primary-text)"}/>
										)}

										{finished && successIfNotErr("sent user email")}
									</dd>
								</div>
							</dl>
						</div>

						{showResendBtn && !emailRequested && !emailRequestedError && (
							<SendEmailBtn onClick={() => reSendEmail()}>Re-send config email</SendEmailBtn>
						)}

						{emailRequested && (
							<SuccessMsg>Config email has been sent to your address</SuccessMsg>
						)}

						{emailRequestedError && (
							<ErrorMsg>There was an error. Please try again later</ErrorMsg>
						)}
					</div>
				)}


				{!props.server.server?.id && !props.server.loading && (
					<div className="rounded-md bg-red-50 p-4">
						<div className="flex">
							<div className="flex-shrink-0">
								<svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd"
										 d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
										 clipRule="evenodd"/>
								</svg>
							</div>
							<div className="ml-3">
								<h3 className="text-normal leading-5 font-medium text-red-800">
									Invalid server
								</h3>
								<div className="mt-2 text-normal leading-5 text-red-700">
									<ul className="list-disc pl-5">
										<li>
											The provided server was not found!
										</li>
										<li className="mt-1">
											It may have been deleted from our records as we regularly do so.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				)}
			</Wrapper>
		</Theme>
	);
};


const mapStateToProps = (state: iState) => {
	return {
		form  : state.form,
		server: state.server,
	};
};

const mapDispatchToProps = {request, reset,};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Success);
