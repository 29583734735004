import React from 'react';

import tw, {styled} from 'twin.macro';

const Wrapper = styled.div(
	() => [
		tw``
	]
);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	size?: number;
	title?: string;
	color?: string;
}

const Loading: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="inline-flex items-center text-base font-medium rounded-md text-primary-background transition ease-in-out duration-150">
				<svg
					className={`animate-spin -ml-1 mr-3 h-${props.size || "12"} w-${props.size || "12"}`}
					xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<circle
						className="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke={props.color || "blue"}
						strokeWidth="4"
					/>
					<path
						className="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					/>
				</svg>
				{props.title && <p style={{
					color: props.color || "blue"
				}}>{props.title}</p>}
			</div>
		</Wrapper>
	);
};

export default Loading;
